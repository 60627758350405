import template from './finanzierung-datenschutz.html';

/**
 * Am Anfang der Strecke werden dem Anwender Datenschutz angezeigt,
 * welche er für den weiteren Verlauf der OBS benötigt.
 */
export { FinanzierungDatenschutzComponent};

const FinanzierungDatenschutzComponent = {
  template: template
};
