import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngAnimate from 'angular-animate';
import componentModule from '../../components/components.module';
import commonModule from '../../common/common.module';
import FinanzierungsvorhabenService from './finanzierungsvorhaben.service';
import FinanzierungswunschService from './finanzierungswunsch/finanzierungswunsch.service';
import KalkulationParameterService from './kalkulation-parameter.service';
import { FinanzierungsvorhabenComponent } from './finanzierungsvorhaben.component';
import { FinanzierungswunschComponent } from './finanzierungswunsch/finanzierungswunsch.component';
import { FinanzierungHinweiseComponent } from './hinweise/finanzierung-hinweise.component';
import { FinanzierungDatenschutzComponent } from './datenschutz/finanzierung-datenschutz.component';

/**
 * Modul obs.finanzierungsvorhaben stellt die Einstiegsmaske der OBS bereit.
 * Der Anwender gibt seinen Finanzierungswunsch ein.
 */
export default angular
  .module('obs.finanzierungsvorhaben', [
    ngAnimate,
    uiRouter,
    commonModule,
    componentModule
  ])
  .service('finanzierungsvorhabenService', FinanzierungsvorhabenService)
  .service('finanzierungswunschService', FinanzierungswunschService)
  .service('kalkulationParameterService', KalkulationParameterService)
  .component('finanzierungsvorhaben', FinanzierungsvorhabenComponent)
  .component('finanzierungswunsch', FinanzierungswunschComponent)
  .component('finanzierungHinweise', FinanzierungHinweiseComponent)
  .component('finanzierungDatenschutz', FinanzierungDatenschutzComponent)
  .name;

